.NoSearch {
  text-align: center;
  width: 100%;
  height: 100%;
  background-color: #f3f6fb;
}
.NoSearch_img {
  width: 60%;
  height: auto;
}
.NoSearch_content {
  width: 100%;
}

.NoSearch_title {
  font-family: "vag_Bold";
  color: #52747d;
  text-transform: uppercase;
  font-size: 24px;
  width: 80%;
  padding-left: 10%;
}

.NoSearch_paragraphe {
  font-family: "openSans_Light";
  color: #52747d;
  font-size: 16px;
  width: 80%;
  padding-left: 10%;
}
