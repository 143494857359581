@font-face {
  font-family: "bellaboo";
  src: url("./fonts/BELLABOO-Regular.otf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "gotham_Bold";
  src: url("./fonts/Gotham%20Rounded%20Bold.otf");
}

@font-face {
  font-family: "gotham_Meduim";
  src: url("./fonts/Gotham%20Rounded%20Medium.otf");
}

@font-face {
  font-family: "vag_Bold";
  src: url("./fonts/VAG.ttf");
}

@font-face {
  font-family: "openSans_Bold";
  src: url("./fonts/OpenSans-Bold.ttf");
}

@font-face {
  font-family: "openSans";
  src: url("./fonts/OpenSans-Regular.ttf");
}

@font-face {
  font-family: "openSans_Semibold";
  src: url("./fonts/OpenSans-Semibold.ttf");
}

@font-face {
  font-family: "openSans_Light";
  src: url("./fonts/OpenSans-Light.ttf");
}

@font-face {
  font-family: "Times_New_Romance";
  src: url("./fonts/Times New Romance.ttf");
}

@font-face {
  font-family: "Roboto_Italic";
  src: url("./fonts/Roboto-Italic.ttf");
}

@font-face {
  font-family: "Roboto_BoldItalic";
  src: url("./fonts/Roboto-BoldItalic.ttf");
}

input::placeholder {
  font-family: "openSans_Semibold";
  font-size: 12px;
  color: #52747d;
}
html{
  scroll-behavior: smooth;
}