.about_horaire {
  display: flex;
  justify-content: space-between;
}
.about_horaire_bold {
  display: flex;
  justify-content: space-between;
}

.about_horaire_bold .day p {
  font-family: "openSans_Bold" !important;
  color: #a9babf;
  font-size: 14px;
}

.day {
  width: 33%;
}

.day p {
  font-family: "openSans_Semibold";
  color: #a9babf;
  font-size: 14px;
  margin: 3px;
}
