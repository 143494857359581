.PharmacieCard {
  display: flex;
  background-color: #ffffff;
  border: 1px solid #e7e7e7;
  border-radius: 3px;
  margin-left: 1%;
}
.harmacie_card_forma {
  /* width: 40%; */
}
.photo_empty {
  /* margin-left: 5%;
    margin-top: 5%; */
}
.CardBody_PharmacieCard {
}
.card_title {
  font-family: "openSans_Bold";
  font-size: 13px;
  color: #52747d;
  margin-bottom: 15px;
}
.card_paragraphe {
  font-family: "openSans";
  font-size: 12px;
  color: #91a8ae;
  display: flex;
  align-items: center;
}
.card_paragraphe .icon {
  margin-right: 6px !important;
}
