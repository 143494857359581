.PharmacyList {
  background-color: #00c389;
  background: linear-gradient(to right, #1bc48a, #1bc4c3);
}
.PharmacyList_input {
  margin-top: 13%;
  text-align: center;
  padding-bottom: 6%;
}
.PharmacyList_contect {
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 16px;
  overflow: scroll;
  height: 100%;
}

.PharmacyList_container {
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: #f3f6fb;
  overflow: scroll;
}

.pharmacie_card {
  background-color: #ffff;
  padding: 10px;
  display: flex;
  margin-bottom: 10px;
  align-items: center;
  justify-content: space-between;
  border-radius: 3px;
}

.pharmacy_list_photo {
  padding-right: 10px;
}

.pharmacie_name {
  font-family: "openSans_Bold";
  font-size: 13px;
  color: #52747d;
  margin-bottom: 0px;
  margin-top: 0px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
}

.pharmacie_info {
  font-family: "openSans";
  font-size: 12px;
  color: #91a8ae;
  margin-bottom: 0px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
}

.pharmacie_card_pharma_info_container{
  width: 64%;
  overflow: hidden;
}
.pharmacie_icons {
  margin-right: 5px;
  font-size: 15px;
  color: #52747d;
}

.BsChevronRight_icon {
  text-align: end;
}

.pharmacie_card_data_container {
  display: flex;
  align-items: center;
  width: 96%;
}

.search_input::placeholder {
  color: #7e7d7d;
  opacity: 1; /* Firefox */
}

.search_input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #7e7d7d;
}

.search_input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #7e7d7d;
}
