.BottomPopup_closed {
  width: 100%;
  height: 100%;
  position: absolute;
  left: -100%;
  top: 0;
  bottom: 0;
  transition: all 0.5s;
  z-index: 99;
}

.BottomPopup {
  width: 100%;
  height: auto;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  transition: all 0.5s;
  z-index: 99;
  text-align: center;
  position: fixed;
}

.BottomPopup .BottomPopup-nav {
  height: 80%;
  background-color: #fff;
  overflow-x: scroll;
  border-radius: 30px 30px 0px 0px;
  padding-left: 16px;
  padding-right: 16px;
}

.BottomPopup .BottomPopup-wrapper {
  /* background-color: rgba(0, 0, 0, 0.5); */
  height: 20%;
  width: 100%;
  float: right;
  cursor: pointer;
}

.BottomPopup_contact {
  display: flex;
}

.BottomPopup_paragraphe {
  font-family: "openSans";
  font-size: 12px;
  color: #a9babf;
  text-align: start;
}

.BottomPopup_title {
  font-family: "vag_Bold";
  font-size: 24px;
  color: #52747d;
  text-align: start;
}
.BottomPopup_AiOutlineClose {
  font-size: 40px;
  color: #b8afaf;
}

.Sing_in_facebook_button {
  width: 100%;
  border: none;
  background-color: #69c3a7 !important;
  color: #ffff;
  font-size: 15px;
  border-radius: 6px;
  margin-bottom: 3%;
  font-family: "openSans_Light";
  display: flex;
  align-items: center;
}

.Sing_in_facebook_button p {
  text-align: center;
}

.BottomPopup_google_button {
  width: 100%;
  border: none;
  background-color: #f5f5f5 !important;
  color: #c5c5c5;
  font-size: 15px;
  border-radius: 6px;
  margin-bottom: 3%;
  font-family: "openSans_Light";
  display: flex;
  align-items: center;
}

.BottomPopup_input_button {
  width: 100%;
  background-color: #69c3a7;
  color: #ffffff;
  font-size: 15px;
  border: none;
  padding: 5% 0% !important;
  border-radius: 6px;
  margin-top: 2%;
  margin-bottom: 3%;
  font-family: "gotham_Bold";
}

.BottomPopup_border_start::after {
  content: "";
  width: 35%;
  height: 1px;
  position: absolute;
  left: 5%;
  /* top: 0; */
  background-color: #e3e3e3;
}

.BottomPopup_border_end::after {
  content: "";
  width: 35%;
  height: 1px;
  position: absolute;
  right: 5%;
  /* top: 0; */
  background-color: #e3e3e3;
}

.Option_border {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.BottomPopup_border_paragraphe {
  font-family: "gotham_Bold";
  font-size: 15px;
  color: #53747c;
}

.BottomPopup_Sobrus_button img {
  width: 25px;
}

.BottomPopup_Sobrus_button {
  width: 100%;
  border: none;
  background-color: #ffff !important;
  border-radius: 6px;
  margin-bottom: 3%;
  display: flex;
  align-items: center;
  border: 1px solid #19b0d2;
}
.BottomPopup_Sobrus_button p {
  font-size: 14px;
  font-family: "openSans_Semibold";
  text-align: center;
  color: #494949;
}
