.ProfileEdit {
  background-color: #00c389;
  background: linear-gradient(to right, #1bc48a, #1bc4c3);
  position: fixed;
  height: 100%;
  width: 100%;
}
.ProfileEdit_contect {
  margin-top: 10%;
  padding-left: 16px;
  padding-right: 16px; 
}
.cancel_input_button {
  background-color: #ffffff;
  color: #5a797f;
  font-size: 15px;
  border: none;
  padding: 13px 0% !important;
  border-radius: 6px;
  margin-bottom: 5%;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  font-family: "openSans_Light";
  width: 100%;
}

.search_input_edit {
  box-shadow: none;
  color: #00c389;
  border: 2px solid #e5eef7;
  background-color: #ffffff;
  padding-top: 25px;
  border-radius: 6px;
}
.search_input_edit:focus {
  border: none !important;
}
.form-group {
  position: relative;
  margin-top: 2%;
}

.form-group label {
  position: absolute;
  color: #bdbdbd;
  top: 8%;
  left: 4%;
  font-size: 13px;
}
