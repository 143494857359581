.Accueil {
  background-color: #00c389;
  background: linear-gradient(to right, #1bc48a, #1bc4c3);
}
.Accueil_paragraphe {
  color: #ffffff;
  font-size: 24px;
  font-family: "vag_Bold";
}
.input_button {
  width: 100%;
  background-color: #f6ac32;
  color: #ffffff;
  font-size: 14px;
  border: none;
  padding: 13px 0%;
  border-radius: 6px;
  margin-top: 2%;
  margin-bottom: 3%;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  font-family: "openSans_Semibold";
}
.search_input {
  margin-top: 2%;
  padding: 3% 0%;
  border: 1px solid #e5eef7 !important;
  background-color: #ffffff;
  border-radius: 6px;
}
.Accueil_contect {
  /* display: flex;
  flex-direction: column; */
  padding-left: 10px;
  padding-right: 10px;
}
.border_inside {
  border: 2px solid transparent !important;
  padding: 14px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  width: 100%;
}

.card_slider {
  background-color: #f3f6fb;
  padding: 2%;
  padding-left: 10px;
  padding-right: 10px;
}
.who_are_we {
  padding-left: 10px;
  padding-right: 10px;
}
.lastPharma_paragraphe {
  color: #52747d;
  font-size: 14px;
  font-family: "vag_Bold";
}

.Accueil_pharmacieCard {
  display: flex;
  overflow-x: auto;
}
.Accueil_paragraphe_green {
  color: #00c389;
  font-size: 14px;
  font-family: "vag_Bold";
}
.Accueil_paragraphe_buttom {
  font-family: "openSans";
  font-size: 12px;
  color: #52747d;
}
.cookiePopup {
  background-color: #52747d;
  border-radius: 8px 8px 0px 0px;
  position: absolute;
  left: 0;
  right: 0;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 16px;
}
.cookiePopup_title {
  color: #ffffff;
  font-size: 18px;
  font-family: "vag_Bold";
  text-align: start;
}

.cookiePopup_paragraph {
  color: #ffffff;
  font-size: 12px;
  font-family: "openSans_Semibold";
  text-align: start;
}
.cookiePopup_paragraph a {
  font-family: "openSans_Semibold";
  font-size: 12px;
  color: #00c389;
}
.cookiePopup_button {
  background-color: #f6ac32;
  color: #ffffff;
  font-size: 14px;
  border: none;
  border-radius: 6px;
  margin: auto;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  font-family: "openSans_Semibold";
  padding: 11px 38px;
}

.cookiePopupPopup .BottomPopup-wrapper {
  height: 75%;
  width: 100%;
  cursor: pointer;
}

.cookiePopupPopup .cookiePopup {
  background-color: #52747d;
  border-radius: 8px 8px 0px 0px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 16px;
  height: 25%;
  overflow-x: scroll;
}
.cookiePopup_closed {
  width: 100%;
  height: 100%;
  position: absolute;
  left: -100%;
  top: 0;
  bottom: 0;
  z-index: 99;
}

.cookiePopupPopup {
  width: 100%;
  height: auto;
  position: absolute !important;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 99;
  position: fixed;
}
