.RankingPopup {
}
.ranking_data {
  margin-bottom: 5%;
}
.ranking_buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.ranking_button {
  font-family: "gotham_Bold";
  font-size: 15px;
  color: #748d94;
  border: 1px solid #e0e7ed;
  border-radius: 50%;
  padding: 2% 4%;
}
.ranking_button_active {
  background-color: #5cd8b4;
  color: #ffffff;
}
.UpdatePop_button {
  width: 100%;
  background-color: #f5f5f5;
  color: #c5c5c5;
  font-size: 15px;
  border: none;
  padding: 5% 0% !important;
  border-radius: 6px;
  margin-top: 2%;
  margin-bottom: 3%;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  font-family: "openSans_Light";
  text-align: center;
}
.demandePopup_contact {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 2%;
  padding-right: 2%;
}

.BottomPopup .BottomPopup-wrapper {
  /* background-color: rgba(0, 0, 0, 0.5) ; */
  height: 20%;
  width: 100%;
  float: right;
  cursor: pointer;
}

.rating_error {
  background-color: #f2dedf;
  padding: 10px;
  border-radius: 3px;
  margin-bottom: 10px;
  border: 1px solid #bb4845;
}

.rating_error p {
  font-size: 12px;
  font-family: "openSans";
  color: #ba4a46;
  text-align: unset;
  margin-bottom: 0px;
  margin-top: 0px;
}
