.Sing_in_middle {
  background-color: #00c389;
  background: linear-gradient(to right, #1bc48a, #1bc4c3);
  text-align: center;
  height: 100vh;
  padding-left: 16px;
  padding-right: 16px;
}

.Sing_in_middle_title {
  font-family: "vag_Bold";
  font-size: 24px;
  color: #ffffff;
  text-align: start;
}

.Sing_in_middle_container {
  background-color: #ffffff;
  padding: 10px 40px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  justify-content: space-between;
}

.Sing_in_middle_pharmacie {
  font-family: "openSans_Semibold";
  font-size: 14px;
  color: #5cc58a;
  display: flex;
}

.Sing_in_middle_Patient {
  font-family: "openSans_Semibold";
  font-size: 14px;
  color: #f6ac32;
  display: flex;
}
.Sing_in_middle_Patient_icon {
  margin-left: 5px;
  font-size: 20px;
}
.Sing_in {
  background-color: #00c389;
  background: linear-gradient(to right, #1bc48a, #1bc4c3);
  text-align: center;
  height: 100vh;
}

.Sing_in_title {
  font-family: "vag_Bold";
  font-size: 24px;
  color: #ffffff;
  text-align: start;
  padding-left: 2%;
}

.Sing_in_paragraphe {
  font-family: "openSans";
  font-size: 13px;
  color: #ffffff;
  text-align: start;
  padding-left: 2%;
}

.Sing_in_passwrod {
  font-family: "openSans_Light";
  font-size: 14px;
  color: #ffffff;
}

.Sing_in_form {
  padding-left: 2%;
  padding-right: 2%;
}

.Sing_in_facebook_button {
  width: 100%;
  border: none;
  background-color: #4267b2 !important;
  border-radius: 6px;
  margin-bottom: 3%;
  display: flex;
  align-items: center;
}

.Sing_in_facebook_button p {
  text-align: center;
  font-family: "openSans_Semibold";
  text-align: center;
  color: #ffff;
}

.Sing_in_google_button {
  width: 100%;
  border: none;
  background-color: #ffff !important;
  border-radius: 6px;
  margin-bottom: 3%;
  display: flex;
  align-items: center;
}

.Sing_in_google_button p {
  font-size: 14px;
  font-family: "openSans_Semibold";
  text-align: center;
  color: #494949;
}

.Sing_in_facebook_icon {
  float: left;
  font-size: 20px;
  margin-left: 5%;
  margin-right: 10%;
}

.Sing_in_Sobrus_button img {
  width: 25px;
}

.Sing_in_Sobrus_button {
  width: 100%;
  border: none;
  background-color: #ffff !important;
  border-radius: 6px;
  margin-bottom: 3%;
  display: flex;
  align-items: center;
}
.Sing_in_Sobrus_button p {
  font-size: 14px;
  font-family: "openSans_Semibold";
  text-align: center;
  color: #494949;
}
.Sign_up_doc {
  background-color: #00c389;
  background: linear-gradient(to right, #1bc48a, #1bc4c3);
  padding-bottom: 20vh;
}
.Sign_up {
  background-color: #00c389;
  background: linear-gradient(to right, #1bc48a, #1bc4c3);
  padding-bottom: 5%;
}

.Sign_up_title {
  font-family: "vag_Bold";
  font-size: 24px;
  color: #ffffff;
  text-align: start;
}

.Sign_up_paragraphe {
  font-family: "openSans_Light";
  font-size: 13px;
  color: #ffffff;
}

.Sign_up_form {
  padding-left: 10px;
  padding-right: 10px;
}

.inscription_checkbox {
  display: flex;
  color: #ffff;
}

.erreurInputText {
  font-family: "openSans_Light";
  font-size: 12px;
  color: #700000;
  text-align: start;
}

.Sign_up_form_button {
  margin-top: 20px;
  margin-bottom: 20px;
}
.PharmacyProfile_header {
  background-color: #00c389;
  background: linear-gradient(to right, #1bc48a, #1bc4c3);
}
.PharmacyProfile {
  background-color: #f3f6fb;
  padding-bottom: 5%;
}
.photo_de_coverture {
  width: 100%;
  height: 15vh;
}
.PharmacyProfile_name {
  text-align: center;
  font-family: "openSans_Bold";
  color: #5a797f;
  text-transform: uppercase;
  font-size: 14px;
  margin-top: 10%;
}
.PharmacyProfile_school {
  text-align: center;
  font-family: "openSans_Light";
  color: #9ba5a9;
  font-size: 11px;
}

.fabrique_title {
  text-align: start;
  font-family: "openSans";
  color: #5cd8b4;
  padding-left: 2%;
  padding-top: 5%;
  width: max-content;
  position: relative;
}
.fabrique_title::after {
  content: " ";
  position: absolute;
  height: 1px;
  background-color: #5cd8b4;
  bottom: -5px;
  left: 6%;
  right: 0px;
}
.pharma_info_title {
  font-family: "openSans_Bold";
  color: #5a797f;
  font-size: 14px;
}

.pharma_info_contect {
  font-family: "openSans_Semibold";
  color: #a9babf;
  font-size: 14px;
  margin: 0px;
}

.pharma_info_icon {
  margin-right: 6px;
}
.pharma_info {
  display: flex;
  justify-content: space-between;
}
.pharma_general_data {
  background-color: #ffffff;
  padding: 3%;
  margin-top: 16px;
  margin-bottom: 16px;
  border-radius: 3px;
}

.pharma_info_real_icon {
  color: #5cd8b4;
  margin-right: 6px;
}
.modes_paiement {
  display: flex;
  padding-left: 0px;
  overflow-x: scroll;
}
.modes_paiement li {
  width: 134px;
  height: 85px;
  border-radius: 3px;
  background-color: #f5f9fd;
  display: inline-block;
  margin-right: 5px;
  text-align: center;
  padding-top: 3%;
}
.modes_paiement p {
  font-family: "openSans-Light";
  font-size: 13px;
  text-align: center;
  color: #789198;
  margin-top: 10px;
}
.popUpButton {
  position: absolute;
  z-index: 100000;
  top: 69%;
  left: 20%;
  padding: 2% 15%;
  background-color: #eda225 !important;
  color: #fff !important;
  border-radius: 3px !important;
  border: 2px solid #fff !important;
  font-family: "gotham-Bold", sans-serif !important;
  font-size: 12px !important;
  box-shadow: none !important;
}

.popUpButton:hover {
  position: absolute;
  z-index: 0;
  top: 69%;
  left: 25%;
  padding: 6px 45px;
  background-color: #eda225 !important;
  color: #fff !important;
  border-radius: 3px !important;
  border: 2px solid #fff !important;
  font-family: "gotham-Bold", sans-serif !important;
  font-size: 12px !important;
  box-shadow: none !important;
}

.MapModelModalDialog {
  max-width: 1500px !important;
}

.IoIosThumbsUp_button {
  width: 35%;
  background-color: #01ab78;
  color: #ffffff;
  font-size: 15px;
  border: none;
  border-radius: 6px;
  padding: 4%;
  font-size: 20px;
}
.IoIosThumbsDown_button {
  width: 35%;
  background-color: #f65f6e;
  color: #ffffff;
  font-size: 15px;
  border: none;
  border-radius: 6px;
  padding: 4%;
  font-size: 20px;
}
.IoIosThumbs_bottons {
  display: flex;
  justify-content: space-around;
}
.social_media {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.social_media_icons {
  font-size: 30px;
  margin-left: 14px;
}
.Pencil_icons {
  font-size: 15px;
  margin-right: 14px;
  color: #789198 !important;
}

.Pencil_fabrique_title {
  text-align: start;
  font-family: "openSans_Light";
  color: #5cd8b4;
  text-decoration: underline;
}

.MapModel_card {
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  left: 0;
  right: 0;
}

.AiFillCloseCircle_icon {
  font-size: 30px;
  color: red;
  position: absolute;
  right: 10px;
  top: -10px;
}

/* .profile_vide_data_add_image_form {
  display: flex;
  align-items: center;
  background-color: #eda225;
  padding: 3% 5%;
  border-radius: 6px;
} */

.profile_vide_data_add_image {
  font-family: "openSans_Light";
  font-size: 14px;
  color: #fff;
  margin: 0px;
}

.profile_vide_data_add_image_icon {
  font-size: 20px;
  color: #fff;
  padding-left: 19px;
}
