.FavoritPharmacie {
  padding-left: 16px;
  padding-right: 16px;
}
.FavoritPharmacie_container {
  background: linear-gradient(to right, #1bc48a, #1bc4c3);
  position: fixed;
  height: 100%;
  width: 100%;
}

.FavoritPharmacie_title {
  font-family: "vag_Bold";
  font-size: 24px;
  color: #ffffff;
  text-align: start;
  margin-bottom: 0px;
}

.FavoritPharmacie_paragraphe {
  font-family: "openSans";
  font-size: 13px;
  color: #ffffff;
  text-align: start;
  margin-bottom: 10px;
  width: 33%;
}

.Info_data_title {
  font-family: "openSans_Bold";
  color: #52747d;
  margin-top: 0px;
}

.FavoritPharmacie_name {
  font-family: "openSans_Bold";
  color: #5a797f;
  width: 33%;
}

.FavoritPharmacie_form {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pharmacie_favorit_data {
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
}

.FavoritPharmacie_icon {
  color: #faae33;
  font-size: 20px;
}

.Pharmacie_city {
  margin-left: 25%;
}

.pharmacie_favorit_data_container {
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  height: 80px;
  padding-left: 10px;
  padding-right: 10px;
  overflow: hidden;
}

.empty_array_container {
  text-align: center;
  margin-top: 30px;
}
.empty_array_container p {
  font-family: "openSans";
  font-size: 16px;
  color: #ffffff;
  margin-top: 28px;
}
.empty_array_container button {
  padding: 15px 60px;
  border: 1px solid #ffffff;
  background-color: transparent;
  border-radius: 8px;
  font-family: "openSans";
  font-size: 16px;
  color: #ffffff;
  margin-top: 28px;
}
