.PersonnelInfo {
  background-color: #00c389;
  background: linear-gradient(to right, #1bc48a, #1bc4c3);
  text-align: center;
  height: 60vh;
}
.PersonnelInfo_title {
  font-family: "vag_Bold";
  font-size: 24px;
  color: #ffffff;
  text-align: start;
  padding-left: 16px;
  margin-bottom: 0px;
}
.PersonnelInfo_paragraphe {
  font-family: "openSans";
  font-size: 13px;
  color: #ffffff;
  text-align: start;
  padding-left: 16px;
  padding-right: 16px;
}

.Info_data_container {
  margin-left: 16px;
  margin-right: 16px;
  position: absolute;
  left: 0;
  right: 0;
  top: 30vh;
}

.Info_data {
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 3px;
  background-color: #ffffff;
  padding-top: 10px;
  padding-bottom: 4px;
}

.Info_data_container_privecy {
  font-family: "openSans_Light";
  font-size: 12px;
  color: #a9babf;
  padding-left: 10px;
  padding-right: 10px;
}

.PersonnelInfo_border::after {
  content: "";
  width: 96%;
  height: 1px;
  position: absolute;
  left: 2%;
  background-color: #f3f7fb;
}

.Info_data_title {
  font-family: "openSans_Semibold";
  color: #52747d;
  font-size: 14px;
  margin-bottom: 0px;
  margin-top: 0px;
}

.Info_data_paragraphe {
  font-family: "openSans";
  font-size: 16px;
  color: #a9babf;
  margin-top: 0px;
}

.change_passeword_title {
  font-family: "openSans_Bold";
  color: #00c389;
}

.change_passeword_paragraphe {
  font-family: "openSans_Light" !important;
  color: #00c389;
}

.Info_data_form_button {
  background-color: #f3f6fb;
  height: 50vh;
  padding-left: 16px;
  padding-right: 16px;
}

.change_passeword_icon {
  font-size: 20px;
}
.update_data_input_button {
  margin-top: 40vh;
}
.generale_info_from_account {
  position: relative;
  margin-bottom: 30px;
}
.generale_info_from_account_title {
  font-family: "openSans_Bold";
  color: #52747d;
  font-size: 18px;
  margin-top: 0px;
}
.generale_info_from_account::after {
  content: "";
  width: 100%;
  height: 1px;
  position: absolute;
  background-color: #f3f6fb;
}
