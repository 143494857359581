.ConsultedPharmacie {
  background: linear-gradient(to right, #1bc48a, #1bc4c3);
  /* text-align: center; */
  padding-bottom: 100%;
  position: fixed;
  height: 100%;
  width: 100%;
}
.ConsultedPharmacie_container {
  padding-left: 16px;
  padding-right: 16px;
}

.ConsultedPharmacie_title {
  font-family: "vag_Bold";
  font-size: 24px;
  color: #ffffff;
  text-align: start;
  margin-bottom: 0px;
}

.ConsultedPharmacie_paragraphe {
  font-family: "openSans";
  font-size: 13px;
  color: #ffffff;
  text-align: start;
  margin-bottom: 10px;
  width: 33%;
}
.CompleteRegistration_skip {
  font-family: "openSans_Light";
  font-size: 13px;
  color: #ffffff;
  text-align: center;
  cursor: pointer;
}
.Info_data_title {
  font-family: "openSans_Bold";
  color: #5a797f;
  margin-top: 0px;
}

.ConsultedPharmacie_name {
  font-family: "openSans_Bold";
  color: #5a797f;
}

.ConsultedPharmacie_form {
  display: flex;
  align-items: center;
}

.pharmacie_favorit_data {
  margin-top: 5%;
  padding: 3%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
}

.ConsultedPharmacie_icon {
  color: #faae33;
  font-size: 20px;
}

.Pharmacie_city {
  margin-left: 25%;
}
