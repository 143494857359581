.header {
  padding-bottom: 22px;
  text-align: center;
  padding-top: 22px;
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
  align-items: center;
}
.Hamburger {
  color: #ffffff;
  /* padding: 2%; */
}

.aside_closed {
  width: 100%;
  position: absolute;
  left: -100%;
  top: 0;
  bottom: 0;
  transition: all 0.5s;
  animation: 0.5s myanimation;
  z-index: 99;
}
.aside_closed .aside-nav {
  animation: 0.5s aside_animation;
}

.aside {
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  transition: all 1s;
  z-index: 99;
  text-align: center;
}

.aside .aside-nav {
  width: 80%;
  background: #fff;
  height: 100%;
  float: left;
  overflow-x: scroll;
  transition: all 0.7s;
}

.aside .aside-wrapper {
  background-color: rgba(0, 0, 0, 0.5);
  width: 20%;
  height: 100%;
  float: right;
  cursor: pointer;
  transition: all 1s;
}

.AiOutlineClose {
  font-size: 25px;
  color: #ffff;
  padding-top: 9%;
}

.connect_input_button {
  width: 100%;
  border: 1px solid #00c389;
  background-color: #ffff !important;
  color: #00c389;
  font-size: 13px;
  padding: 13px 0px;
  border-radius: 6px;
  margin-top: 2%;
  margin-bottom: 3%;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  font-family: "openSans_Semibold";
}

.header_margin_top {
  margin-top: 10%;
}

.header_border::after {
  content: "";
  width: 73%;
  height: 1px;
  position: absolute;
  left: 16px;
  /* top: 0; */
  background-color: #e3e3e3;
}

.color_header {
  display: flex;
  align-items: center;
  color: #52747d;
  font-family: "openSans";
  font-size: 13px;
  padding-top: 5%;
  text-align: start;
}

.header_border_icon {
  font-size: 25px;
  margin-right: 4%;
  color: #afcad2;
}

.header_logo_monPharmacien {
  max-width: 200px;
  display: flex;
  margin-top: 15%;
}

.loged_header_info {
  display: flex;
  align-items: center;
  padding-top: 30px;
}
.user_img_contaner {
  width: 30%;
}

.user_img {
  width: 80%;
  height: auto;
  /* border: 1.5px solid #00c389; */
  border-radius: 6px;
}

.logedIn_user_info a {
  font-family: "openSans";
  font-size: 13px;
  color: #52747d;
  display: flex;
}

.logedIn_user_info p {
  font-family: "openSans_Bold";
  font-size: 15px;
  color: #52747d;
  text-align: start !important;
  margin-bottom: 0px;
  margin-top: 0px;
}

.header_slider {
  padding-left: 10px;
  padding-right: 10px;
}

.logout_header {
  color: #ff504d;
}
.logout_header_icon {
  font-size: 25px;
  margin-right: 4%;
  color: #ff504d;
}

.Logo_monPharmacien {
  max-width: 120px;
  margin: auto;
}

.go_to_pro_link_icon {
  font-size: 20px;
  color: #07c389;
  margin-right: 9px;
}

.go_to_pro_link_selected {
  display: flex;
  font-family: "openSans_Bold";
  font-size: 17px;
  color: #07c389;
  margin-bottom: 6px;
}

.go_to_pro_link {
  font-family: "openSans";
  font-size: 13px;
  color: #91a8ae;
}

.go_to_pro_link_container {
  display: flex;
  align-items: center;
}

.privacy_policy_link {
  font-family: "openSans";
  font-size: 13px;
  color: #91a8ae;
  position: absolute;
  left: 20px;
  bottom: 20px;
  text-decoration: underline;
}

@keyframes myanimation {
  0% {
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0.9;
  }
  5% {
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0.7;
  }
  20% {
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0.5;
  }
  25% {
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0.3;
  }
  100% {
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0.1;
  }
}

@keyframes aside_animation {
  0% {
    background-color: #fff;
    left: 0%;
    opacity: 0.9;
  }
  5% {
    background-color: #fff;
    left: -25%;
    opacity: 0.7;
  }
  20% {
    background-color: #fff;
    left: -50%;
    opacity: 0.5;
  }
  25% {
    background-color: #fff;
    left: -75%;
    opacity: 0.3;
  }
  100% {
    background-color: #fff;
    left: -100%;
    opacity: 0.1;
  }
}
