.FavoritPharmacie_title {
  font-family: "vag_Bold";
  font-size: 24px;
  color: #ffffff;
  text-transform: uppercase;
}
.PrivacyPolicy {
  background: linear-gradient(to right, #1bc48a, #1bc4c3);
  /* text-align: center; */
  padding-bottom: 20px;
  padding-left: 16px;
  padding-right: 16px;
}
.PrivacyPolicy_container {
  padding: 16px;
}
.PrivacyPolicy_container_title {
  font-family: "Roboto_BoldItalic";
  font-size: 19px;
  color: #52747d;
}
.PrivacyPolicy_container_paragraph {
  font-family: "Roboto_Italic";
  font-size: 16px;
  color: #284358;
  margin-top: 0px;
}
.loader_background {
  position: fixed;
  background-color: #fffffff0;
  z-index: 100000;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.PrivacyPolicy_text_container {
  margin-bottom: 50px;
}

.PrivacyPolicy_table {
  border-top: 1px solid #bebebe;
  width: 100%;
  margin-bottom: 25px;
}
.PrivacyPolicy_table thead {
  background-color: #f7f7f7;
}
.PrivacyPolicy_table thead th {
  font-family: "Roboto_Italic";
  font-size: 16px;
  color: #284358;
  font-weight: normal;
}
.PrivacyPolicy_table thead th,
.PrivacyPolicy_table td,
.PrivacyPolicy_table th {
  border-bottom: 1px solid #bebebe;
  border-left: 1px solid #bebebe;
  border-right: 1px solid #bebebe;
  padding: 22px 10px;
}
.PrivacyPolicy_pharagraphes a {
  color: #2680eb;
  text-decoration: underline;
}
.PrivacyPolicy_sub_conatianer {
  padding-left: 10px;
  padding-bottom: 30px;
}

.PrivacyPolicy_title {
  font-family: "Roboto_BoldItalic";
  font-size: 16px;
  color: #284358;
}

.PrivacyPolicy_pharagraphes_with_dot {
  display: list-item;
  list-style-type: disc;
  list-style-position: inside;
  margin-top: 0px;
}
