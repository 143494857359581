.Contact {
  background-color: #00c389;
  background: linear-gradient(to right, #1bc48a, #1bc4c3);
  text-align: center;
  height: 100%;
  padding-bottom: 20px;
  position: fixed;
  overflow: scroll;
}

.Contact_title {
  font-family: "gotham_Bold";
  font-size: 17px;
  color: #ffffff;
  text-align: start;
  padding-left: 2%;
}
.Contact_paragraphe {
  font-family: "openSans_Light";
  font-size: 14px;
  color: #ffffff;
  text-align: start;
  padding-left: 2%;
}
.contact_form {
  padding-left: 2%;
  padding-right: 2%;
  /* margin-top: 10%; */
}

.contact_form_button {
  /* margin-top: 17%; */
}

.successMessage {
  background: #68bf58;
  border-bottom: 1px solid #68bf58;
  text-align: center;
  padding: 15px;
  color: #fff;
  font: 12px "OpenSans", sans-serif;
  margin: 0px !important;
}

.message_input_textarea::placeholder {
  font-family: Arial !important;
}
.select_option:optional:first-child {
  color: #7e7d7d;
}

.PhotoDeProfileUploadPopup_ckeckbox {
  display: flex;
  text-align: start;
  font-family: "openSans";
  font-size: 13px;
  color: #ffffff;
}

.PhotoDeProfileUploadPopup_ckeckbox_container {
  text-align: start;
}

.PhotoDeProfileUploadPopup_ckeckbox_title {
  font-family: "openSans";
  font-size: 13px;
  color: #ffffff;
}
.PhotoDeProfileUploadPopup_ckeckbox_title_error {
  font-family: "openSans";
  font-size: 13px;
  color: #d03535;
}

.PhotoDeProfileUploadPopup_ckeckbox_link {
  font-family: "openSans";
  font-size: 13px;
  color: #f6ac32;
  text-decoration: underline;
}
