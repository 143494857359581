.image_conatiner_PharmacieSearched {
  width: 100px;
  height: 90px;
  overflow: hidden;
}
.image_conatiner_PharmacieSearched img {
  width: 90%;
  height: 100%;
  object-fit: cover;
}
