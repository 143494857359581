.Error_header {
  background-color: #00c389;
  background: linear-gradient(to right, #1bc48a, #1bc4c3);
  text-align: center;
}

.Error {
}

.Error_form_button {
  position: absolute;
  bottom: 0;
  left: 3%;
  right: 0;
}

.Error_title {
  font-family: "vag_Bold";
  font-size: 24px;
  color: #52747d;
  text-transform: uppercase;
  text-align: center;
  margin-top: 30px;
}

.Error_img {
  width: 100%;
  margin-top: 50px;
}

.Error_paragraphe {
  font-family: "openSans_Light";
  font-size: 16px;
  color: #52747d;
  text-align: center;
  padding-left: 30px;
  padding-right: 30px;
}

.Error_components {
  padding-left: 16px;
  padding-right: 16px;
}
