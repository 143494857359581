.PharmacyProfileVide_header {
  background-color: #00c389;
  background: linear-gradient(to right, #1bc48a, #1bc4c3);
}

.PharmacyProfileVide {
  background-color: #f3f6fb;
  padding-bottom: 3vh;
}

.photo_de_covertureVide {
  width: 100%;
  margin-top: -5vh;
  height: 100%;
  margin-top: -5vh;
  object-fit: cover;
}

.photo_de_ProfileVide {
  height: auto;
  /* position: absolute;
  top: 25vh;
  left: 36%; */
  text-align: center;
  margin-top: -62px;
}

.photo_de_ProfileVide img {
  width: 25%;
}

.photo_de_profile {
  /* width: 25%; */
  height: auto;
  border-radius: 50%;
  /* position: absolute;
  z-index: 1;
  top: 25vh;
  left: 40%; */
  text-align: center;
  margin-top: -62px;
}
.photo_de_profile img {
  height: 100px;
  width: 100px;
  object-fit: cover;
  border-radius: 50%;
}

.nom_de_pharmacie {
  text-align: center;
  font-family: "openSans_Bold";
  color: #52747d;
  text-transform: uppercase;
  font-size: 18px;
  margin-bottom: 0px;
}

.PharmacyProfileVide_contect {
  /* margin-top: 55px; */
  background-color: #f3f7fb;
}

.pharmacy_profile_vide_button {
  padding: 3%;
  background-color: transparent !important;
  color: #00c38954 !important;
  border-radius: 20px !important;
  border: 1px solid #00c389 !important;
  font-family: "gotham_Meduim" !important;
  font-size: 12px !important;
  box-shadow: none !important;
  margin-top: 2%;
}

.pharmacy_profile_vide_fivorite_button {
  padding: 3% 5%;
  background-color: transparent !important;
  color: #f2ab37 !important;
  border-radius: 20px !important;
  border: 1px solid #f2ab37 !important;
  font-family: "gotham_Meduim" !important;
  font-size: 14px !important;
  box-shadow: none !important;
  margin-top: 2%;
}

.pharmacy_profile_vide_fivorite_button_clicked {
  padding: 3% 5%;
  background-color: #f2ab37 !important;
  color: #ffffff !important;
  border-radius: 20px !important;
  border: 1px solid #f2ab37 !important;
  font-family: "gotham_Meduim" !important;
  font-size: 14px !important;
  box-shadow: none !important;
  margin-top: 2%;
}

.pharmacy_profile_buttons_group {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pharmacy_profile_buttons {
  display: flex;
  align-items: center;
  width: 80%;
}

.question_form {
  text-align: start;
  font-family: "openSans_Semibold";
  text-transform: uppercase;
  color: #ffffff;
  padding: 0% 2%;
  background-color: #f6ac33;
  border: 1px solid #f6ac33;
  border-radius: 6px;
  font-size: 13px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.question_form div p {
  margin: 0px;
}

.question_form div img {
  margin-top: 5px;
}

.profile_vide_data_title {
  font-family: "openSans_Bold";
  color: #52747d;
  font-size: 14px;
  text-align: start;
  margin-bottom: 0px;
}

.profile_vide_data_link {
  text-align: start;
  font-family: "openSans_Light";
  color: #5cd8b4;
  margin-top: 0px;
}

.profile_vide_AiOutlinePlus {
  color: #f6ac33;
}

.profile_vide_data_popup_opner {
  position: relative;
}

.spokenLanguages_container {
  display: flex;
  align-items: center;
  width: 99%;
  flex-wrap: wrap;
}

.profile_vide_input_button {
  width: 100%;
  background-color: #5cd8b4;
  color: #ffffff;
  font-size: 15px;
  border: none;
  padding: 17px 0px;
  border-radius: 6px;
  font-family: "openSans_Light";
  text-align: center;
}

.profile_vide_input_button:focus {
  border: none !important;
}

.profile_vide_input_button_icon {
  padding-right: 20px;
}

.call_button_position {
  position: sticky;
  bottom: 3%;
}

.pharmacie_paragraphe {
  font-family: "openSans";
  font-size: 14px;
  color: #91a8ae;
  text-align: center;
  padding-left: 30px;
  padding-right: 30px;
  margin-bottom: 0px;
  margin-top: 0px;
}

.pharma_info_contect::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 1px;
  left: -16px;
  background-color: #f3f7fb;
  bottom: -6px;
}

.facebook_icon {
  color: #39579a;
}

.whatsapp_icon {
  color: #77d268;
}

.availableServices_icon {
  color: #00c389;
  font-size: 12px;
  margin-left: 5px;
  margin-right: 5px;
}

.availableServices_container {
  display: flex;
  align-items: center;
}

.PaiementPopup {
  background-color: #f3f7fb;
  height: 100px;
  width: 100px;
  text-align: center;
  margin: 3px;
  overflow: hidden;
}

.availablePaymentMethods_container {
  display: flex;
  flex-wrap: wrap;
}

.PaiementPopup_title {
  font-family: "openSans_Semibold";
  font-size: 12px;
  color: #52747d;
}

.PaiementPopup img {
  transform: translateY(18px);
  width: 40px;
  margin-bottom: 7px;
}

.photo_cover_container {
  width: 100%;
  height: 150px;
  overflow: hidden;
}
.photoDePharmacy {
  background-color: #f3f7fb;
  margin: 3px;
  border-radius: 3px;
}
.photoDePharmacy_container {
  display: flex;
  overflow-y: scroll;
}
.photoDePharmacy_image {
  width: 150px;
  max-height: 100px;
  object-fit: cover;
}
.leaflet-interactive {
  display: none !important;
}
.map_marker_container {
  font-family: Arial, Helvetica, sans-serif;
  display: flex;
  align-items: center;
  color: #00c389;
  font-size: 12px;
  text-align: center;
}
.map_marker_icon {
  font-size: 20px;
  color: #f65f6e;
  margin-right: 3px;
}

.FcPlus_maps_icon {
  font-size: 20px;
  color: #f65f6e;
  background-color: #ffffff;
  border-radius: 50%;
  box-shadow: 1px 1px 7px 3px #f65f6e;
}
